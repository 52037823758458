























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import VideoGridNew from '@/components/VideoGridNew.vue';
import {ModelDataInterface} from '@/types/userDataInterface';
import {vxm} from '@/store';
import {VideoInterface} from '@/types/videoInterface';
import {VideosSearchParamsInterface} from '@/types/VideosSearchParamsInterface';
import Loader from '@/components/Loader.vue';

@Component({components: {Loader, VideoGridNew}})
export default class ModelVids extends Vue {
  @Prop() readonly model!: ModelDataInterface;
  @Prop({default: false}) readonly isSubscribed!: boolean;

  vids: VideoInterface[] = [];
  isRequestSend = false;
  totalVids = 0;
  params: VideosSearchParamsInterface = {
    page: 1,
    take: 9,
    performerId: '',
    isSaleVideo: this.isSaleVids,
    isPurchased: undefined,
    sort: 'newest',
    performerRole: '',
  };

  mounted() {
    if (this.model) {
      this.params.performerId = this.model._id;
      const storage = JSON.parse(localStorage.getItem('fan') as string);
      if (
        storage &&
        storage[this.$route.name as string] &&
        this.params.performerId === storage[this.$route.name as string].performerId
      ) {
        this.totalVids = storage[this.$route.name as string].count;
        this.vids.push(...storage[this.$route.name as string].items);
        this.getVids(true, true);
      } else {
        this.getVids(true);
      }
    }
    // window.addEventListener('scroll', this.handleScroll);
  }

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    // const bottomOfWindow = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
    if (!this.isRequestSend && this.totalVids > this.vids.length) {
      this.getVids();
    }
  }

  @Watch('model')
  @Watch('$route')
  onChange() {
    this.params.isSaleVideo = this.isSaleVids;
    this.params.isPurchased = undefined;
    this.params.performerRole = '';
    this.params.page = 1;
    this.vids = [];
    this.params.performerId = this.model._id;
    if (!this.isRequestSend) {
      const storage = JSON.parse(localStorage.getItem('fan') as string);
      if (
        storage &&
        storage[this.$route.name as string] &&
        this.params.performerId === storage[this.$route.name as string].performerId
      ) {
        this.totalVids = storage[this.$route.name as string].count;
        this.vids.push(...storage[this.$route.name as string].items);
        this.getVids(true, true);
      } else {
        this.getVids(true);
      }
    }
  }

  get isSaleVids(): boolean {
    return (
      this.$route.name === 'model-vids-plus' ||
      this.$route.name === 'profile-preview-vids-plus' ||
      this.$route.name === 'performer-vids-plus'
    );
  }

  get paymentSuccessCount() {
    return this.$store.state.paymentSuccessCount;
  }

  @Watch('paymentSuccessCount') onPaymentSuccess() {
    const take = (this.params.page as number) * (this.params.take as number);
    vxm.fan
      .getVideosSearch({...this.params, page: 1, take: take})
      .then((res) => {
        this.totalVids = res.data.count;
        this.vids = res.data.items;
        this.updLocalStorage(res.data);
        // (this.params.page as number)++; remove this line
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.isRequestSend = false;
      });
  }

  changePurchaseFilter(newFilter: boolean) {
    this.params.isPurchased = newFilter ? newFilter : undefined;
    this.params.page = 1;
    this.vids = [];
    this.getVids();
  }

  changePerformerRole(role: '' | 'owner' | 'performer') {
    this.params.performerRole = role;
    this.params.page = 1;
    this.vids = [];
    this.getVids();
  }

  changeSearch(query: string) {
    this.params.keyword = query;
    this.params.page = 1;
    this.vids = [];
    this.getVids();
  }

  changeSort(newSort) {
    this.params.sort = newSort;
    this.params.page = 1;
    this.vids = [];
    this.getVids();
  }

  getVids(isFirst = false, backgroundUpdate = false) {
    this.isRequestSend = !backgroundUpdate;
    if (vxm.user.role === 'performer' && this.model._id !== vxm.user.data._id) {
      vxm.model
        .getMyVideos(this.params)
        .then((res) => {
          this.totalVids = res.data.count;
          if (isFirst) {
            this.vids = res.data.items;
            this.updLocalStorage(res.data);
          } else {
            this.vids.push(...res.data.items);
          }
        })
        .catch((error) => {
          return error;
        })
        .then(() => {
          this.isRequestSend = false;
        });
    } else {
      vxm.fan
        .getVideosSearch(this.params)
        .then((res) => {
          this.totalVids = res.data.count;
          if (isFirst) {
            this.vids = res.data.items;
            this.updLocalStorage(res.data);
          } else {
            this.vids.push(...res.data.items);
          }
          (this.params.page as number)++;
        })
        .catch((error) => {
          return error;
        })
        .then(() => {
          this.isRequestSend = false;
        });
    }
  }
  updLocalStorage(data) {
    let storage = JSON.parse(localStorage.getItem('fan') as string);
    if (!storage) {
      storage = {};
      storage[this.$route.name as string] = {items: [], count: 0, performerId: ''};
    }
    storage[this.$route.name as string] = {
      performerId: this.params.performerId,
      items: data.items,
      count: data.count,
    };
    localStorage.setItem('fan', JSON.stringify(storage));
  }
}
